<template>
  <div class="main">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span>用户列表 : 总注册数{{total}}</span>
        <div style="margin-top: 18px; display: flex; flex-wrap: wrap">
          <div class="item">
            <span class="label">首次访问时间： </span>
            <el-date-picker
              size="small"
              v-model="filterData.created_at"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd HH:mm:ss"
              style="width: 240px"
            />
          </div>
          <div class="item">
            <span class="label">最近一次访问时间： </span>
            <el-date-picker
              size="small"
              v-model="filterData.laster_visit_at"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd HH:mm:ss"
              style="width: 240px"
            />
          </div>
          <div class="item">
            <span class="label">首次收藏商品时间： </span>
            <el-date-picker
              size="small"
              v-model="filterData.first_collection_goods_at"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd HH:mm:ss"
              style="width: 240px"
            />
          </div>
          <div class="item">
            <span class="label">首次下单时间： </span>
            <el-date-picker
              size="small"
              v-model="filterData.first_pay_order_at"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd HH:mm:ss"
              style="width: 240px"
            />
          </div>
        </div>
        <div style="display: flex; flex-wrap: wrap">
          <div class="item">
            <span class="label">关键词： </span>
            <el-input
              size="small"
              v-model="filterData.phone"
              placeholder="输入关键字搜索"
              clearable
              style="width: 240px"
            />
          </div>
          <div class="item">
            <span class="label">注册设备： </span>
            <el-select
              size="small"
              style="width: 240px"
              v-model="filterData.register_device"
              filterable
              clearable
              placeholder="全部"
            >
              <el-option
                v-for="item in ['手机', '电脑', '小程序']"
                :key="item"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </div>
          <div class="item">
            <span class="label">收藏商品数量： </span>
            <el-select
              size="small"
              style="width: 240px"
              v-model="filterData.collection_goods_nums"
              filterable
              clearable
              placeholder="全部"
            >
              <el-option
                v-for="item in [
                  { label: '3个以内', value: ['0', '3'] },
                  { label: '4~9个', value: ['4', '9'] },
                  { label: '10个以上', value: ['10', '1000000'] },
                ]"
                :key="item.label"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="item">
            <span class="label">是否为公众号粉丝： </span>
            <el-select
              size="small"
              style="width: 240px"
              v-model="filterData.is_sub_official_account"
              filterable
              clearable
              placeholder="全部"
            >
              <el-option
                v-for="item in [
                  { label: '是', value: 2 },
                  { label: '否', value: 1 },
                ]"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="item">
            <span class="label">当月内登录次数： </span>
            <el-select
              size="small"
              style="width: 240px"
              v-model="filterData.curr_monthly_login_times"
              filterable
              clearable
              placeholder="全部"
            >
              <el-option
                v-for="item in [
                  { label: '3次以内', value: ['0', '3'] },
                  { label: '4~9次', value: ['4', '9'] },
                  { label: '10次以上', value: ['10', '1000000'] },
                ]"
                :key="item.label"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div class="item">
            <span class="label">微信内分享次数： </span>
            <el-select
              size="small"
              style="width: 240px"
              v-model="filterData.wechat_share_nums"
              filterable
              clearable
              placeholder="全部"
            >
              <el-option
                v-for="item in [
                  { label: '3次以内', value: ['0', '3'] },
                  { label: '4~9次', value: ['4', '9'] },
                  { label: '10次以上', value: ['10', '1000000'] },
                ]"
                :key="item.label"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
        <div style="padding-left: 130px">
          <el-button
            size="small"
            type="primary"
            @click="query(1)"
            icon="el-icon-search"
          >
            查询
          </el-button>
          <el-button
            size="small"
            @click="filterData = { page: 1 }"
            icon="el-icon-plus"
          >
            重置
          </el-button>
        </div>
      </div>
      <el-table
        :data="tableData"
        style="width: 100%"
        size="mini"
        :cell-style="{ paddingTop: '12px', paddingBottom: '10px' }"
      >
        <el-table-column type="selection" width="55" />
        <el-table-column prop="unionid" label="UnionId" />
        <el-table-column prop="phone" label="⼿机号" />
        <el-table-column prop="created_at" label="首次访问时间" />
        <el-table-column label="地域">
          <template slot-scope="scope">
            {{ scope.row.province }} - {{ scope.row.city }} -
            {{ scope.row.county }}
          </template>
        </el-table-column>
        <el-table-column prop="created_at" label="注册时间" />
        <el-table-column prop="register_device" label="注册设备" />
        <el-table-column
          prop="first_collection_goods_at"
          label="首次收藏商品时间"
        />
        <el-table-column prop="collection_goods_nums" label="收藏商品数量" />
        <el-table-column
          prop="curr_monthly_login_times"
          label="当月内登录次数"
        />
        <el-table-column label="是否为公众号粉丝">
          <template slot-scope="scope">
            {{
              {
                0: "未知",
                1: "否",
                2: "是",
              }[scope.row.is_sub_official_account]
            }}
          </template>
        </el-table-column>
        <el-table-column prop="wechat_share_nums" label="微信内分享次数" />
        <el-table-column prop="first_pay_order_at" label="首次下单时间" />
        <el-table-column prop="laster_visit_at" label="最近一次访问时间" />
        <el-table-column label="操作" width="100">
          <template slot-scope="scope">
            <el-button size="small" type="danger" @click="remove(scope.row.id)">
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-row>
        <el-col :span="24" style="text-align: center; margin-top: 10px">
          <el-pagination
            :page-sizes="[10]"
            :page-size="10"
            background
            layout="total,sizes,prev, pager, next"
            @current-change="pageChange"
            :current-page="filterData.page"
            :total="total"
          ></el-pagination>
        </el-col>
      </el-row>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      total: 0,
      rule: [],
      filterData: { page: 1 },
      tableData: [],
      form: {},
    };
  },

  created() {
    this.query();
  },

  methods: {
    pageChange(page) {
      this.filterData.page = page;
      this.query();
    },
    query(page) {
      if (page) this.filterData.page = page;
      this.$http
        .post("/admin/stats/user", this.filterData)
        .then((res) => {
          if (res.status === 200) {
            this.tableData = res.data.data;
            this.total = res.data.total;
          }
        })
        .catch(() => {});
    },
    remove(id) {
      this.$confirm("此操作将永久删除该条记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$http.post("/admin/user/remove", { id: id }).then((res) => {
            if (res.status === 200) {
              this.$message({
                message: "删除成功",
                type: "success",
              });
              this.query();
            }
          });
        })
        .catch(() => {});
    },

    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$http.post("/admin/user/save", this.form).then((res) => {
            if (res.status === 200) {
              this.$message({
                message: "操作成功",
                type: "success",
              });
              this.dialogFormVisible = false;
              this.form = {};
              this.query();
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.main /deep/ .el-date-editor .el-range-separator {
  box-sizing: content-box;
  width: 10%;
}
.label {
  white-space: nowrap;
  width: 120px;
  display: inline-block;
  text-align: right;
  margin-right: 10px;
}
.item {
  margin-bottom: 10px;
  margin-right: 10px;
}
</style>
